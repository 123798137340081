<template>
  <div>
    <component-menu></component-menu>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 mb-2">
          <h2>{{$t("lang.application.video_converter.name")}}</h2>
          <p class="fw-light">{{$t("lang.application.video_converter.detailed_description")}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mb-2" id="create-form-errors" v-if="createForm.errors.length > 0">
          <div class="alert alert-danger">
            <div v-for="error in createForm.errors" :key="error">
              {{ error }}
            </div>
          </div>
        </div>
        <div class="col-sm-12 mb-2">
          <div class="card">
            <div class="card-body">
              <div v-show="process.loaded === true">
                <div class="row mb-2">
                  <div class="col-lg-12">
                    <div class="progress">
                      <div class="progress-bar progress-bar-success progress-bar-striped" id="process-progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width:0%;">0%</div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-lg-12 text-center">{{process.message}}</div>
                </div>
                <div class="row mb-2">
                  <div class="col-lg-12 text-center">
                    <label class="checkbox-inline" v-show="process.completed === false">
                      <input type="checkbox" v-model="createForm.auto_download">{{$t("lang.label.auto_download")}}
                    </label>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-lg-12">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <span>
                        <a href="javascript:window.location.reload();" class="btn btn-warning btn-sm">{{$t("lang.label.previous")}}</a>
                      </span>
                      <span>
                        <button type="button" class="btn btn-info btn-sm" disabled v-show="process.completed === false">{{$t("lang.label.download")}}</button>
                        <a href="#" class="btn btn-info btn-sm" id="process-download-link" download="" v-show="process.completed === true">{{$t("lang.label.download")}}</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="process.loaded === false">
                <div class="row mb-2">
                  <label for="inputMediaFile" class="col-sm-2 form-label">{{$t("lang.label.source_file")}}</label>
                  <div class="col-sm-10">
                    <input type="file" class="form-control" id="inputMediaFile" v-if="isWorkerLoaded === true">
                    <div v-else><img src="/images/loading-fading.gif"></div>
                  </div>
                </div>
                <div class="row mb-2">
                  <label for="outputType" class="col-sm-2 form-label">{{$t("lang.label.destination_format")}}</label>
                  <div class="col-sm-10">
                    <select class="form-select" id="outputType" v-model="createForm.type" @change="setDefaultConfigure">
                      <option :value="`${key}`" v-for="(outputType, key) in outputVideoTypes" :key="key">{{outputType.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12 text-center">
                    <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" @click="toggleAdvancedSetting()">{{$t("lang.label.advanced_setting")}}</button>
                  </div>
                </div>
                <div id="advanced-setting">
                  <!--
                  <div class="row mb-2">
                    <label for="outputSize" class="col-sm-2 form-label">{{$t("lang.label.size")}}</label>
                    <div class="col-sm-10">
                      <select class="form-select" id="outputSize" v-model="createForm.video_size">
                        <option value="">{{$t("lang.label.not_change")}}</option>
                        <option :value="`${size}`" v-for="(size, key) in outputVideoTypes[createForm.type].use_video_sizes" :key="key">
                          {{videoSettingSizes[size].name}} {{videoSettingSizes[size].description}} {{videoSettingSizes[size].aspect}}
                        </option>
                      </select>
                    </div>
                  </div>
                  -->
                  <div class="row mb-2" v-if="outputVideoTypes[createForm.type].use_video_encoders.length > 0">
                    <label class="col-sm-2 form-label">{{$t("lang.application.video_converter.label.video_setting")}}</label>
                    <div class="col-sm-10">
                      <div class="row mb-2">
                        <label for="videoEncoder" class="col-sm-2 form-label">{{$t("lang.label.encoder")}}</label>
                        <div class="col-sm-10">
                          <select class="form-select" id="videoEncoder" v-model="createForm.video_encoder">
                            <option :value="`${encoder}`" v-for="(encoder, key) in outputVideoTypes[createForm.type].use_video_encoders" :key="key">{{videoEncoders[encoder].name}}</option>
                          </select>
                        </div>
                      </div>
                      <!--
                      <div class="row mb-2">
                        <label for="videoFrameRate" class="col-sm-2 form-label">{{$t("lang.label.frame_rate")}}</label>
                        <div class="col-sm-10">
                          <select class="form-select" id="videoFrameRate" v-model="createForm.video_frame_rate">
                            <option value=''>{{$t("lang.label.not_change")}}</option>
                            <option :value="`${value}`" v-for="(value, key) in videoFrameRateValues" :key="key">{{value}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <label for="videoBitRate" class="col-sm-2 form-label">{{$t("lang.label.bit_rate")}}</label>
                        <div class="col-sm-10">
                          <select class="form-select" id="videoBitRate" v-model="createForm.video_bit_rate">
                            <option value=''>{{$t("lang.label.not_change")}}</option>
                            <option :value="`${value}`" v-for="(value, key) in videoBitRateValues" :key="key">{{value}}</option>
                          </select>
                        </div>
                      </div>
                      -->
                    </div>
                  </div>
                  <div class="row mb-2" v-if="outputVideoTypes[createForm.type].use_audio_encoders.length > 0">
                    <label class="col-sm-2 form-label">{{$t("lang.application.video_converter.label.audio_setting")}}</label>
                    <div class="col-sm-10">
                      <div class="row mb-2">
                        <label for="audioEncoder" class="col-sm-2 form-label">{{$t("lang.label.encoder")}}</label>
                        <div class="col-sm-10">
                          <select class="form-select" id="audioEncoder" v-model="createForm.audio_encoder">
                            <option :value="`${encoder}`" v-for="(encoder, key) in outputVideoTypes[createForm.type].use_audio_encoders" :key="key">{{audioEncoders[encoder].name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2" v-if="outputVideoTypes[createForm.type].default.audio_sampling_rate != 'disabled'">
                        <label for="audioSamplingRate" class="col-sm-2 form-label">{{$t("lang.label.sampling_rate")}}</label>
                        <div class="col-sm-10">
                          <select class="form-select" id="audioSamplingRate" v-model="createForm.audio_sampling_rate">
                            <option value=''>{{$t("lang.label.not_change")}}</option>
                            <option :value="`${value}`" v-for="(value, key) in audioSamplingRateValues" :key="key">{{value}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-2" v-if="outputVideoTypes[createForm.type].default.audio_bit_rate != 'disabled'">
                        <label for="audioBitRate" class="col-sm-2 form-label">{{$t("lang.label.bit_rate")}}</label>
                        <div class="col-sm-10">
                          <select class="form-select" id="audioBitRate" v-model="createForm.audio_bit_rate">
                            <option value=''>{{$t("lang.label.not_change")}}</option>
                            <option :value="`${value}`" v-for="(value, key) in audioBitRateValues" :key="key">{{value}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-12 text-end">
                    <label class="checkbox-inline">
                      <input type="checkbox" v-model="createForm.auto_download">{{$t("lang.label.auto_download")}}
                    </label>
                    &nbsp;
                    <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" @click="executeProcess($event)">{{$t("lang.label.convert")}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component-footer></component-footer>
  </div>
</template>

<script>
  import Vue from 'vue';
  import $ from 'jquery';
  import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
  export default {
    metaInfo : {
      title: `${window.application.title} ${window.application.lang.application.video_converter.name}`,
      meta: [
        {name: 'description', conent: `${window.application.lang.application.video_converter.meta_description}`}
      ]
    },
    head: {
      link: [
        {rel: 'alternate', hreflang: 'en', href: 'https://ezeful.app/en/videoconverter'},
        {rel: 'alternate', hreflang: 'es', href: 'https://ezeful.app/es/videoconverter'},
        {rel: 'alternate', hreflang: 'hi', href: 'https://ezeful.app/hi/videoconverter'},
        {rel: 'alternate', hreflang: 'ja', href: 'https://ezeful.app/ja/videoconverter'},
        {rel: 'alternate', hreflang: 'zh-cn', href: 'https://ezeful.app/zh_cn/videoconverter'},
        {rel: 'alternate', hreflang: 'zh-tw', href: 'https://ezeful.app/zh_tw/videoconverter'},
      ],
      //script: [
      //  { src: window.application.adsense_script, async: true, crossorigin:"anonymous"},
      //],
    },
    data () {
      return {
        lang: window.application.lang,
        videoEncoders: window.application.converter.encoders.video,
        videoFrameRateValues: window.application.converter.settings.video.frame_rates,
        videoBitRateValues: window.application.converter.settings.video.bit_rates,
        videoSettingSizes: window.application.converter.settings.video.sizes,
        audioEncoders: window.application.converter.encoders.audio,
        audioSamplingRateValues: window.application.converter.settings.audio.sampling_rates,
        audioBitRateValues: window.application.converter.settings.audio.bit_rates,
        outputVideoTypes: window.application.converter.outputs.video,
        //outputAudioTypes: window.application.converter.outputs.audio,
        selectedOutputType: 'mp4',
        createForm: {
          errors: [],
          auto_download: false,
          type: 'mp4',
          video_encoder: '',
          video_size: '',
          video_frame_rate: '',
          video_bit_rate: '',
          audio_encoder: '',
          audio_sampling_rate: '',
          audio_bit_rate: '',
        },
        process: {
          loaded: false,
          completed: false,
          message: null,
          length: 0,
          data:{
            text: ""
          }
        },
        ffmpeg: null,
        //asm vars
        worker: null,
        running: false,
        isWorkerLoaded: false, 
        progressEndPosition: 0,
        currentProgress: 0,
      }
    },
    created() {
      Vue.component('component-menu', require('../components/Menu.vue').default);
      Vue.component('component-footer', require('../components/Footer.vue').default);
    },
    mounted() {
      let self = this;
      self.setDefaultConfigure();
      $('#advanced-setting').hide();
      
      //worker init
      self.initWorker();
    },
    updated() {
      //let self = this;
    },
    methods: {
      isReady() { //ASM Only
        let self = this;
        return !self.running && self.isWorkerLoaded;
      },
      startRunning() { //ASM Only
        let self = this;
        //document.querySelector("#image-loader").style.visibility = "visible";
        //outputElement.className = "";
        //filesElement.innerHTML = "";
        self.running = true;
      },
      stopRunning() { //ASM Only
        let self = this;
        //document.querySelector("#image-loader").style.visibility = "hidden";
        self.running = false;
      },
      initWorker() { //ASM Only
        let self = this;
        self.worker = new Worker("/js/ffmpeg/worker-asm.js");
        self.worker.onmessage = function (event) {
          var message = event.data;
          if (message.type == "ready") {
            self.isWorkerLoaded = true;
            //self.worker.postMessage({
            //  type: "command",
            //  arguments: ["-help"]
            //});
          } else if (message.type == "stdout") {
            //outputElement.textContent += message.data + "\n";
            let regex = /\spos:(\d+)\s/;
            let target = String(message.data);
            let re = new RegExp(regex, 'gmiu');
            let matches = [...target.matchAll(re)];
            matches = JSON.parse(JSON.stringify(matches));
            if (typeof matches[0] !== 'undefined' && typeof matches[0][1] !== 'undefined') {
              let currentPosition = parseInt(String(matches[0][1]));
              let nextProgress = Math.round(currentPosition/self.progressEndPosition*100);
              if (parseInt(nextProgress) > parseInt(self.currentProgress)) {
                self.currentProgress = nextProgress;
                self.process.message = `Complete: ${self.currentProgress}%`;
                self.processProgressBar.attr('aria-valuenow', `${self.currentProgress}`);
                self.processProgressBar.css('width', `${self.currentProgress}%`);
                self.processProgressBar.html(`${self.currentProgress}%`);
              }
            }
            //console.log(matches)
            console.log(message.data);
          } else if (message.type == "start") {
            //outputElement.textContent = "Worker has received command\n";
            console.log("Worker has received command");
          } else if (message.type == "done") {
            self.stopRunning();
            var buffers = message.data;
            if (buffers.length) {
              //outputElement.className = "closed";
              console.log("closed");
            }
            buffers.forEach(function(file) {
              //add Download Link
              self.currentProgress = 100;
              self.processProgressBar.attr('aria-valuenow', `${self.currentProgress}`);
              self.processProgressBar.css('width', `${self.currentProgress}%`);
              self.processProgressBar.html(`${self.currentProgress}%`);

              self.process.message = 'Complete transcoding';
              let downloadLink = document.getElementById('process-download-link');
              let extension = String(file.name).split('.').pop();
              downloadLink.href = URL.createObjectURL(new Blob([file.data], { type: 'video/' + extension }));
              downloadLink.download = file.name;
              self.process.completed = true;
              if (self.createForm.auto_download === true) {
                downloadLink.click();
              }
            });
          }
        };
      },
      setDefaultConfigure(){
        let self = this;

        if (self.outputVideoTypes[self.createForm.type].default.video_encoder != 'disabled') {
          self.createForm.video_encoder = self.outputVideoTypes[self.createForm.type].default.video_encoder;
        } else {
          self.createForm.video_encoder = '';
        }
        //if (self.outputVideoTypes[self.createForm.type].default.video_frame_rate != 'disabled') {
        //  self.createForm.video_frame_rate = self.outputVideoTypes[self.createForm.type].default.video_frame_rate;
        //} else {
        //  self.createForm.video_frame_rate = '';
        //}
        self.createForm.video_frame_rate = '';

        //if (self.outputVideoTypes[self.createForm.type].default.video_bit_rate != 'disabled') {
        //  self.createForm.video_bit_rate = self.outputVideoTypes[self.createForm.type].default.video_bit_rate;
        //} else {
        //  self.createForm.video_bit_rate = '';
        //}
        self.createForm.video_bit_rate = '';

        if (self.outputVideoTypes[self.createForm.type].default.audio_encoder != 'disabled') {
          self.createForm.audio_encoder = self.outputVideoTypes[self.createForm.type].default.audio_encoder;
        } else {
          self.createForm.audio_encoder = '';
        }

        //if (self.outputVideoTypes[self.createForm.type].default.audio_sampling_rate != 'disabled') {
        //  self.createForm.audio_sampling_rate = self.outputVideoTypes[self.createForm.type].default.audio_sampling_rate;
        //} else {
        //  self.createForm.audio_sampling_rate = '';
        //}
        self.createForm.audio_sampling_rate = '';

        //if (self.outputVideoTypes[self.createForm.type].default.audio_bit_rate != 'disabled') {
        //  self.createForm.audio_bit_rate = self.outputVideoTypes[self.createForm.type].default.audio_bit_rate;
        //} else {
        //  self.createForm.audio_bit_rate = '';
        //}
        self.createForm.audio_bit_rate = '';
      },
      toggleAdvancedSetting(){
        $('#advanced-setting').toggle();
        this.setDefaultConfigure();
      },
      baseName(str) {
        str = String(str);
        let base = new String(str).substring(str.lastIndexOf('/') + 1); 
          if(base.lastIndexOf(".") != -1)       
              base = base.substring(0, base.lastIndexOf("."));
        return base;
      },
      testArg() {
        let text = "-t 3 -i input.webm -vf showinfo -strict -2 -c:v libx264 output.mp4";
        text = text.replace(/\s+/g, ' ');
        var args = [];
        // Allow double quotes to not split args.
        text.split('"').forEach(function(t, i) {
          t = t.trim();
          if ((i % 2) === 1) {
            args.push(t);
          } else {
            args = args.concat(t.split(" "));
          }
        });
        return args;

      },
      executeProcess(event) {
        let self = this
        self.createForm.errors = [];
        if (
          document.getElementById('inputMediaFile').files.length === 0 ||
          document.getElementById('inputMediaFile').files[0].size === 0
        ) {
          self.createForm.errors.push(self.lang.validation.no_input);
          window.location.element("#create-form-errors");
          return;
        }
        
        // 動画ファイル以外は処理を止める
        //console.log("MIME Type: " + document.getElementById('inputMediaFile').files[0].type);
        if(
          document.getElementById('inputMediaFile').files[0].type != '' && 
          !document.getElementById('inputMediaFile').files[0].type.match('video.*')
        ) {
          self.createForm.errors.push(self.lang.validation.video.replace(':attribute', self.lang.label.source_file));
          window.location.element("#create-form-errors");
          return;
        }

        let obj = window.button.loading(event);
        window.display.loading(this.process);
        //self.transcodeWasm(document.getElementById('inputMediaFile').files);
        self.transcodeVC(document.getElementById('inputMediaFile').files);
        window.display.reset(this.process);
        window.button.reset(obj);
      },
      transcodeVC (files) {
        let self = this;
        self.progressEndPosition = 0;
        self.currentProgress = 0;
        const { name } = files[0];
        let baseName = self.baseName(name);
        let baseExtension = String(name).split('.').pop();
        let inputFileName = 'input.' + baseExtension;

        self.process.completed = false;
        self.processProgressBar = $('#process-progress-bar');
        
        self.process.message = 'Loading libraries...';

        try {
          self.process.message = 'Start transcoding';
          
          //self.ffmpeg.FS('writeFile', name, await fetchFile(files[0]));
          let format = self.createForm.type;
          //let type = self.outputVideoTypes[format].type;
          let extension = self.outputVideoTypes[format].extension;
          
          let videoEncoderOption = '';
          let videoEncoderValue = '';
          let videoSizeOption = '';
          let videoSizeValue = '';
          let videoFrameRateOption = '';
          let videoFrameRateValue = '';
          let videoBitRateOption = '';
          let videoBitRateValue = '';
          let audioEncoderOption = '';
          let audioEncoderValue = '';
          let audioSamplingRateOption = '';
          let audioSamplingRateValue = '';
          let audioBitRateOption = '';
          let audioBitRateValue = '';
          let strictOption = '';
          let strictValue = '';
          let pixelFormatOption = '';
          let pixelFormatValue = '';
          
          if (self.createForm.video_encoder != '') {
            videoEncoderOption = '-c:v';
            videoEncoderValue = self.videoEncoders[self.createForm.video_encoder].encoder;
          }

          if (self.createForm.video_size != '') {
            videoSizeOption = '-s';
            videoSizeValue = self.videoSettingSizes[self.createForm.video_size].value;
          }

          if (self.createForm.video_frame_rate != '') {
            videoFrameRateOption = '-r';
            videoFrameRateValue = self.createForm.video_frame_rate;
          }
          
          if (self.createForm.video_bit_rate != '') {
            videoBitRateOption = '-b:v';
            videoBitRateValue = self.createForm.video_bit_rate;
          }

          if (self.createForm.audio_encoder != '') {
            audioEncoderOption = '-c:a';
            audioEncoderValue = self.audioEncoders[self.createForm.audio_encoder].encoder;
          }

          if (self.createForm.audio_sampling_rate != '') {
            audioSamplingRateOption = '-ar';
            audioSamplingRateValue = self.createForm.audio_sampling_rate;
          }

          if (self.createForm.audio_bit_rate != '') {
            audioBitRateOption = '-b:a';
            audioBitRateValue = self.createForm.audio_bit_rate;
          }
          
          if (videoEncoderValue == 'libx265') {
            pixelFormatOption = '-pix_fmt';
            pixelFormatValue = 'yuv420p10le';
          }
          
          //if (audioEncoderValue == 'aac') {
            strictOption = '-strict';
            strictValue = '-2';
          //}

          self.initWorker();
          //console.log(self.isReady())
          if (self.isReady()) {
            self.startRunning();

            let args = [];
            let tempArgs = [
              //'-t', 
              //'3',
              '-i',
              inputFileName,
              '-vf',
              'showinfo',
              strictOption,
              strictValue,
              //'-f',
              //extension,
              //'-y',
              videoEncoderOption,
              videoEncoderValue,
              videoSizeOption,
              videoSizeValue,
              videoFrameRateOption,
              videoFrameRateValue,
              videoBitRateOption,
              videoBitRateValue,
              audioEncoderOption,
              audioEncoderValue,
              audioSamplingRateOption,
              audioSamplingRateValue,
              audioBitRateOption,
              audioBitRateValue,
              pixelFormatOption,
              pixelFormatValue,
              baseName + '.' + extension
            ];
            for (let i in tempArgs) {
              if (tempArgs[i] != '' && tempArgs[i] != null) {
                args.push(tempArgs[i]);
              }
            }
            
            //args = self.testArg();
            //console.log(args);
            
            const reader = new FileReader();
            reader.addEventListener('load', event => {
              let inputFileData = new Uint8Array(event.target.result);
              self.progressEndPosition = parseInt(inputFileData.length);
              //console.log(inputFileData)
              self.worker.postMessage({
                type: "command",
                arguments: args,
                files: [
                  {
                    "name": inputFileName,
                    "data": inputFileData
                  }
                ]
              });
            });
            reader.readAsArrayBuffer(files[0]);
          }

        } catch (e) {
          //console.log(e);
          self.process.message = self.lang.validation.unknown;
          //self.createForm.errors.push(self.lang.validation.unknown);
          window.location.element("#create-form-errors");
        } finally {
          //window.location.reload();
          //try {
          //  self.ffmpeg.exit();
          //} catch (e) {
          //  console.log(e);
          //} finally {
          //  self.ffmpeg = null;
          //}
        }
      },
      async transcodeWasm (files) {
        let self = this
        const { name } = files[0];
        let baseName = self.baseName(name);

        self.process.completed = false;
        self.processProgressBar = $('#process-progress-bar');
        
        self.ffmpeg = createFFmpeg({
          log: true,
          progress: ({ ratio }) => {
            self.process.message = `Complete: ${(ratio * 100.0).toFixed(2)}%`;
            self.processProgressBar.attr('aria-valuenow', `${(ratio * 100.0).toFixed(2)}`);
            self.processProgressBar.css('width', `${(ratio * 100.0).toFixed(2)}%`);
            self.processProgressBar.html(`${(ratio * 100.0).toFixed(2)}%`);
          },
        });

        self.process.message = 'Loading libraries...';
        await self.ffmpeg.load();
        
        try {
          self.process.message = 'Start transcoding';
          self.ffmpeg.FS('writeFile', name, await fetchFile(files[0]));
          let format = self.createForm.type;
          //let type = self.outputVideoTypes[format].type;
          let extension = self.outputVideoTypes[format].extension;
          
          let videoEncoderOption = '';
          let videoEncoderValue = '';
          let videoSizeOption = '';
          let videoSizeValue = '';
          let videoFrameRateOption = '';
          let videoFrameRateValue = '';
          let videoBitRateOption = '';
          let videoBitRateValue = '';
          let audioEncoderOption = '';
          let audioEncoderValue = '';
          let audioSamplingRateOption = '';
          let audioSamplingRateValue = '';
          let audioBitRateOption = '';
          let audioBitRateValue = '';

          let pixelFormatOption = '';
          let pixelFormatValue = '';
          
          if (self.createForm.video_encoder != '') {
            videoEncoderOption = '-c:v';
            videoEncoderValue = self.videoEncoders[self.createForm.video_encoder].encoder;
          }

          if (self.createForm.video_size != '') {
            videoSizeOption = '-s';
            videoSizeValue = self.videoSettingSizes[self.createForm.video_size].value;
          }

          if (self.createForm.video_frame_rate != '') {
            videoFrameRateOption = '-r';
            videoFrameRateValue = self.createForm.video_frame_rate;
          }
          if (self.createForm.video_bit_rate != '') {
            videoBitRateOption = '-b:v';
            videoBitRateValue = self.createForm.video_bit_rate;
          }

          if (self.createForm.audio_encoder != '') {
            audioEncoderOption = '-c:a';
            audioEncoderValue = self.audioEncoders[self.createForm.audio_encoder].encoder;
          }

          if (self.createForm.audio_sampling_rate != '') {
            audioSamplingRateOption = '-ar';
            audioSamplingRateValue = self.createForm.audio_sampling_rate;
          }
          if (self.createForm.audio_bit_rate != '') {
            audioBitRateOption = '-b:a';
            audioBitRateValue = self.createForm.audio_bit_rate;
          }
          
          if (videoEncoderValue == 'libx265') {
            pixelFormatOption = '-pix_fmt';
            pixelFormatValue = 'yuv420p10le';
          }

          await self.ffmpeg.run(
            '-i',
            name,
            videoEncoderOption,
            videoEncoderValue,
            videoSizeOption,
            videoSizeValue,
            videoFrameRateOption,
            videoFrameRateValue,
            videoBitRateOption,
            videoBitRateValue,
            audioEncoderOption,
            audioEncoderValue,
            audioSamplingRateOption,
            audioSamplingRateValue,
            audioBitRateOption,
            audioBitRateValue,
            pixelFormatOption,
            pixelFormatValue,
            'output.' + extension
          );
          self.process.message = 'Complete transcoding';
          const data = self.ffmpeg.FS('readFile', 'output.' + extension);
          //const outputMedia = document.getElementById('outputMediaPlayer');
          //outputMedia.src = URL.createObjectURL(new Blob([data.buffer], { type: 'video/' + extension }));
          //self.ffmpeg.FS('unlink', 'output.' + extension);

          let downloadLink = document.getElementById('process-download-link');
          downloadLink.href = URL.createObjectURL(new Blob([data.buffer], { type: 'video/' + extension }));
          downloadLink.download = baseName + '.' + extension;
          self.process.completed = true;

          if (self.createForm.auto_download === true) {
            downloadLink.click();
          }
        } catch (e) {
          //console.log(e);
          self.process.message = self.lang.validation.unknown;
          //self.createForm.errors.push(self.lang.validation.unknown);
          window.location.element("#create-form-errors");
        } finally {
          //window.location.reload();
          //try {
          //  self.ffmpeg.exit();
          //} catch (e) {
          //  console.log(e);
          //} finally {
          //  self.ffmpeg = null;
          //}
        }
      }
    }
  }
</script>

<style>

  /** Extra Small Screen (col-xs) ********************************************************/
  @media (max-width: 767px) {

  }

  /** Small Screen (col-sm) **************************************************************/
  @media (min-width: 768px) {

  }

  /** Medium Screen (col-md) *************************************************************/
  @media (min-width: 992px) {

  }
  /** Large Screen (col-lg) **************************************************************/
  @media (min-width: 1200px) { /*元は1200px*/

  }

</style>
